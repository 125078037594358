<template>
	<div class="salesBilling">
		<div class="salesBilling-title">销售开单</div>
		<div class="salesBilling-form">
			<el-form ref="form" :model="form" label-width="100px">
				<el-row :gutter="24">
					<el-col :span="6">
						<el-form-item label="定价策略" required>
							<el-radio-group v-model="form.price_type" :disabled="tableData.length > 0 ? true : false">
								<el-radio :label="1">系统定价</el-radio>
								<el-radio :label="2">自由定价</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="团长" required>
							<el-select v-model="form.leader_member_id" filterable placeholder="请选择团长"
								style="width: 100%;" @change="changeLeader"
								:disabled="tableData.length > 0 ? true : false">
								<el-option v-for="item in customerList" :key="item.member_id" :label="item.name"
									:value="item.member_id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="地址" required>
							<el-select v-model="form.address_id" filterable placeholder="请选择地址" style="width: 100%;">
								<el-option v-for="item in addressList" :key="item.id"
									:label="item.contacts + ',' + item.phone + ',' + item.address" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="仓库" required>
							<el-select v-model="form.warehouse_id" filterable placeholder="请选择仓库" style="width: 100%;">
								<el-option v-for="item in warehouseList" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="提货方式" required>
							<el-select v-model="form.delivery_type" filterable placeholder="请选择提货方式"
								style="width: 100%;" @change="changeDeliveryType">
								<el-option v-for="item in deliveryTypeList" :key="item.id" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="支付方式" required>
							<el-select v-model="form.type" filterable placeholder="请选择支付方式" style="width: 100%;">
								<el-option v-for="item in payList" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6" v-if="form.delivery_type == 2">
						<el-form-item label="运费总金额" required>
							<el-input v-model="form.delivery_fee" placeholder="请输入运费总金额"
								:disabled="form.price_type == 1 ? true : false" @blur="blurFreight"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="商品总金额" required>
							<el-input v-model="form.total_amount" placeholder="请输入商品总金额" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="优惠" required>
							<el-input v-model="form.coupon_amount" placeholder="请输入优惠金额" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="实收" required>
							<el-input v-model="form.amount" placeholder="请输入实收金额" @blur="blurTotalAmount"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="备注">
							<el-input v-model="form.remark" placeholder="请输入备注"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click="addFn()">添加商品</el-button>
			</div>
			<el-table :data="tableData" border :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }">
				<el-table-column label="商品名称" prop="goods_name" align="center" width="180"></el-table-column>
				<el-table-column label="商品主图" align="center">
					<template slot-scope="scope">
						<el-image style="width: 50px; height: 50px" :src="scope.row.goods_pic"
							:preview-src-list="[scope.row.goods_pic]">
						</el-image>
					</template>
				</el-table-column>
				<el-table-column label="限购数量" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.quota == 0 ? '不限购' : scope.row.quota }}</span>
					</template>
				</el-table-column>
				<el-table-column label="最小起购数" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.limit_min == 0 ? '不限' : scope.row.limit_min }}</span>
					</template>
				</el-table-column>
				<el-table-column label="当前库存" prop="stock" align="center"></el-table-column>
				<el-table-column label="销量" prop="sale_num" align="center"></el-table-column>
				<el-table-column label="自定义属性" prop="attribute" align="center"></el-table-column>
				<el-table-column label="配送日期" align="center" width="180" v-if="form.price_type == 2">
					<template slot-scope="scope">
						<el-date-picker v-model="scope.row.arrived_date" type="date" placeholder="请选择配送日期"
							value-format="yyyy-MM-dd" style="width: 100%;" :clearable="false" @change="confirmDate">
						</el-date-picker>
					</template>
				</el-table-column>
				<el-table-column label="单价" align="center">
					<template slot-scope="scope">
						<span v-if="form.price_type == 1">{{ scope.row.price }}</span>
						<el-input v-if="form.price_type == 2" v-model="scope.row.price" placeholder="请输入商品单价"
							@blur="blurPrice(scope.row)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="数量" align="center" width="180">
					<template slot-scope="scope">
						<el-input v-model="scope.row.count" placeholder="请输入商品数量"
							@blur="blurCount(scope.row)"></el-input>
					</template>
				</el-table-column>
				<el-table-column label="小计" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.amount }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 新增编辑 -->
		<el-dialog title="添加商品" :visible.sync="dialogVisible" width="70%" :close-on-click-modal="false"
			v-el-drag-dialog>
			<div class="search" style="padding: 0;margin-bottom: 10px;">
				<div class="search-l">
					<div class="l-item">
						<div class="item-label">商品名称:</div>
						<div class="item-input">
							<el-input v-model="goodsVal" placeholder="请输入商品名称" />
						</div>
					</div>
					<div class="l-item">
						<el-button type="warning"
							@click="goodsPage = 1, goodsLoading = true, goodsList()">搜索</el-button>
					</div>
					<i></i>
					<i></i>
					<i></i>
					<i></i>
				</div>
			</div>
			<div style="height:500px;" class="scrollbar">
				<el-scrollbar style="height:100%">
					<el-table :data="goodsData" border v-loading="goodsLoading" :header-cell-style="{
                        background: '#f5f7fa',
                        fontWeight: 'bold',
                        color: '#303133'
                    }" :header-cell-class-name="leftheaderStyle">
						<el-table-column type="selection" width="55" align="center">
							<template slot-scope="scope">
								<el-checkbox :value="scope.row.goodsChecked ? scope.row.goodsChecked : false"
									@change="goodsItemBox(scope.row, $event)">
								</el-checkbox>
							</template>
						</el-table-column>
						<el-table-column label="商品名称" align="center" width="180">
							<template slot-scope="scope">
								<span>{{ scope.row.goods_name }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品主图" align="center">
							<template slot-scope="scope">
								<el-image style="width: 50px; height: 50px" :src="scope.row.goods_pic"
									:preview-src-list="[scope.row.goods_pic]">
								</el-image>
							</template>
						</el-table-column>
						<el-table-column label="单价" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.price }}</span>
							</template>
						</el-table-column>
						<el-table-column label="限购数量" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.quota == 0 ? '不限购' : scope.row.quota }}</span>
							</template>
						</el-table-column>
						<el-table-column label="最小起购数" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.limit_min == 0 ? '不限' : scope.row.limit_min }}</span>
							</template>
						</el-table-column>
						<el-table-column label="当前库存" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.stock }}</span>
							</template>
						</el-table-column>
						<el-table-column label="销量" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.sale_num }}</span>
							</template>
						</el-table-column>
						<el-table-column label="自定义属性" align="center">
							<template slot-scope="scope">
								<span>{{ scope.row.attribute }}</span>
							</template>
						</el-table-column>
					</el-table>
				</el-scrollbar>
			</div>
			<el-pagination class="page" @size-change="goodsPageNum = $event, goodsLoading = true, goodsList()"
				@current-change="goodsPage = $event, goodsLoading = true, goodsList()" :current-page="goodsPage"
				:page-sizes="goodsPageSizes" layout="total, sizes, prev, pager, next, jumper" :page-size="goodsPageNum"
				:total="goodsTotal">
			</el-pagination>
			<span slot="footer" class="dialog-footer">
				<!-- <el-button @click="dialogVisible = false, selectionChangeTable = []">取 消</el-button> -->
				<el-button type="warning" @click="dialogConfirmFn">确 定</el-button>
			</span>
		</el-dialog>
		<div class="salesBilling-footer">
			<el-button type="success" @click="confirmFn(1)">保存并查看</el-button>
			<el-button type="success" @click="confirmFn(2)">保存并继续</el-button>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					price_type: 1,
					leader_member_id: '',
					address_id: '',
					warehouse_id: '',
					type: '',
					delivery_type: 1,
					delivery_fee: 0,
					delivery_fee_list: JSON.stringify([]),
					amount: 0,
					total_amount: 0,
					coupon_amount: 0,
					remark: '',
				},
				tableData: [],
				tableIndex: '',
				customerList: [], //团长
				addressList: [], //地址
				warehouseList: [], //仓库
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				// 支付方式
				payList: [{
						id: 30,
						name: '线下现金'
					},
					{
						id: 31,
						name: '线下转账'
					},
					{
						id: 32,
						name: '线下扫码'
					},
					{
						id: 39,
						name: '线下其他'
					}
				],
				inputGoodsList: [],
				selectionChangeTable: [], //商品选择框数据
				goodsVal: '',
				goodsData: [],
				goodsLoading: true,
				goodsPage: 1,
				goodsPageNum: 10,
				goodsTotal: 0,
				goodsPageSizes: [10, 50, 200, 1000],
			}
		},
		computed: {},
		mounted() {
			this.getCustomerList()
			this.getWarehouse()
		},
		methods: {
			confirmDate() {
				this.calculatePrice()
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 团长
			getCustomerList() {
				this.$http.post(`erp/v1/customer/lst`, {
					pageNum: 10000
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.customerList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 选团长
			changeLeader(e) {
				this.$http.post(`erp/v1/customer/find`, {
					leader_member_id: e
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.addressList = data.member_address
						if (this.addressList) {
							this.form.address_id = this.addressList[0].id
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouse() {
				this.$http.post(`erp/v1/warehouse/lst`, {
					pageNum: 100
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.warehouseList = data.rows
						if (this.warehouseList) {
							this.form.warehouse_id = this.warehouseList[0].id
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 新增
			addFn() {
				if (!this.form.leader_member_id) {
					this.$message.error('请选择团长');
					return
				}
				if (!this.form.address_id) {
					this.$message.error('请选择地址');
					return
				}
				if (!this.form.warehouse_id) {
					this.$message.error('请选择仓库');
					return
				}
				if (!this.form.delivery_type) {
					this.$message.error('请选择提货方式');
					return
				}
				this.dialogVisible = true
				this.goodsList()
			},
			// 隐藏表头的选择框
			leftheaderStyle({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 0) {
					return "seltAllbtnDis"
				}
			},
			// 单个选择商品
			goodsItemBox(item, e) {
				let index = this.tableData.indexOf(item.id)
				if (e == true) {
					this.$set(item, 'goodsChecked', true)
					this.tableData.push(item)
				} else {
					this.$set(item, 'goodsChecked', false)
					this.tableData.splice(index, 1)
				}
				console.log(this.tableData, ' this.tableData')
			},
			// 商品列表
			goodsList() {
				this.$http.post(`erp/v1/goods/lst_by_leader`, {
					page: this.goodsPage,
					pageNum: this.goodsPageNum,
					key: this.goodsVal,
					leader_member_id: this.form.leader_member_id
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.goodsLoading = false
					if (code == 200) {
						this.goodsData = data.rows
						this.goodsTotal = data.total
						if (this.tableData.length > 0) {
							this.tableData.forEach(i => {
								this.goodsData.forEach(j => {
									if (i.id == j.id) {
										this.$set(j, 'count', i.count)
										this.$set(j, 'goodsChecked', true)
										this.selectionChangeTable.push(j)
									}
								})
							})
						}
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 新增编辑商品确定
			dialogConfirmFn() {
				this.tableData = this.tableData.filter((item, index, self) => {
					const firstIndex = self.findIndex((obj) => obj.id === item.id);
					return index === firstIndex;
				});
				this.tableData.forEach(item => {
					if (this.form.price_type == 2) {
						if (!item.arrived_date) {
							item.arrived_date = this.getCurrentDate()
						}
					}
					if (!item.count) {
						this.$set(item, 'count', 1)
					}
				})
				this.goodsVal = ''
				this.dialogVisible = false
				setTimeout(() => {
					this.calculatePrice()
					// this.selectionChangeTable = []
				}, 500);
			},
			// 删除
			removeFn(item) {
				let index = this.tableData.indexOf(item)
				this.tableData.splice(index, 1)
				this.calculatePrice()
			},
			// 计算价格
			calculatePrice() {
				this.inputGoodsList = []
				this.form.total_amount = 0
				this.tableData.forEach(item => {
					if (Number(item.count) > Number(item.stock)) {
						item.count = item.stock
					}
					this.$set(item, 'amount', (Number(item.price) * Number(item.count)))
					this.form.total_amount += item.amount
					item.amount = item.amount.toFixed(2)
					/* 					if (this.form.delivery_type == 1) {
											// 实收
											this.form.amount = this.form.total_amount.toFixed(2)
											// 优惠
											this.form.coupon_amount = Number(this.form.total_amount) - Number(this.form.amount)
										} */
					if (this.form.price_type == 1) {
						this.inputGoodsList.push({
							goods_id: item.id,
							count: item.count
						})
					} else {
						this.inputGoodsList.push({
							goods_id: item.id,
							count: item.count,
							price: item.price,
							amount: item.amount,
							arrived_date: item.arrived_date
						})
					}
				})
				// 运费计算
				if (this.form.delivery_type == 2) {
					if (this.form.price_type == 1) {
						this.$http.post(`erp/v1/order/calc_delivery_fee`, {
							leader_member_id: this.form.leader_member_id,
							goods_list: JSON.stringify(this.inputGoodsList),
						}).then((res) => {
							const {
								code,
								data,
								msg
							} = res.data
							if (code == 200) {
								this.form.delivery_fee = res.data.data.delivery_fee
								this.form.delivery_fee_list = JSON.stringify(res.data.data.delivery_fee_list)

							} else {
								this.$message.error(msg);
							}
						});
					}
					// 实收
					this.form.amount = Number(this.form.total_amount) + Number(this.form.delivery_fee)
				} else {
					this.form.amount = Number(this.form.total_amount)
				}
				this.form.amount = this.form.amount.toFixed(2)
				// 实收
				this.form.total_amount = this.form.total_amount.toFixed(2)
				// 优惠
				this.form.coupon_amount = Number(this.form.total_amount) + Number(this.form
					.delivery_fee) - Number(this.form.amount)
				this.form.coupon_amount = this.form.coupon_amount.toFixed(2)
			},
			// 切换提货方式
			changeDeliveryType(e) {
				if (this.form.price_type == 1) {
					if (e == 1) {
						this.form.delivery_fee = ''
						this.form.delivery_fee_list = JSON.stringify([])
					}
					this.calculatePrice()
				}
			},
			// 操作商品单价
			blurPrice(item) {
				this.$set(item, 'amount', (Number(item.price) * item.count).toFixed(2))

				setTimeout(() => {
					this.calculatePrice()
				}, 500);
			},
			// 操作商品数量
			blurCount(item) {
				if (Number(item.count) > Number(item.stock)) {
					this.$confirm('输入的商品数量超过库存, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						item.count = item.stock
						this.$set(item, 'amount', (Number(item.price) * item.count).toFixed(2))
						setTimeout(() => {
							this.calculatePrice()
						}, 500);
					}).catch(() => {
						item.count = 1
						this.$set(item, 'amount', (Number(item.price) * item.count).toFixed(2))
						setTimeout(() => {
							this.calculatePrice()
						}, 500);
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				} else {
					this.$set(item, 'amount', (Number(item.price) * item.count).toFixed(2))
					setTimeout(() => {
						this.calculatePrice()
					}, 500);
				}
			},
			// 运费
			blurFreight() {
				this.form.delivery_fee = Number(this.form.delivery_fee).toFixed(2)
				this.form.amount = Number(this.form.total_amount) + Number(this.form.delivery_fee)
			},
			// 实收
			blurTotalAmount() {
				this.form.amount = Number(this.form.amount).toFixed(2)
				this.form.coupon_amount = Number(this.form.total_amount) + Number(this.form.delivery_fee) - Number(this
					.form.amount)
			},
			// 开单
			confirmFn(type) {
				//this.calculatePrice()
				if (!this.form.type) {
					this.$message.error('请选择支付方式');
					return
				}
				if (this.inputGoodsList.length <= 0) {
					this.$message.error('请选择商品');
					return
				}
				setTimeout(() => {
					this.$http.post(`erp/v1/order/add`, {
						...this.form,
						goods_list: JSON.stringify(this.inputGoodsList),
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							if (type == 1) {
								this.$router.push("/orderList");
								// 存储路由
								sessionStorage.setItem('activeMenu', 'orderList');
							} else {
								this.$message.success('操作成功');
							}
							setTimeout(() => {
								window.location.reload();
							}, 1000)
						} else {
							this.$message.error(msg);
						}
					});
				}, 1000)
			}
		},
	}
</script>
<style lang="scss" scoped>
	.salesBilling {
		.salesBilling-title {
			text-align: center;
			font-size: 30px;
			margin-bottom: 20px;
		}

		.salesBilling-form {
			background: #fff;
			padding: 30px 20px 10px 20px;
			border-radius: 10px;
			margin-bottom: 20px;
		}

		.salesBilling-footer {
			display: flex;
			justify-content: center;
			margin-top: 30px;
		}
	}

	.page {
		margin-top: 20px;
		text-align: right;
	}

	::v-deep .seltAllbtnDis .cell {
		visibility: hidden;
	}
</style>